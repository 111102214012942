import Hero from './hero'
import Grid from '@mui/material/Grid'
import { Container, Title, Subtitle, Description, CtaContainer, Link, Button } from './hero-banner.styled'
import { node, object, shape, arrayOf } from 'prop-types'
import { useInView } from 'react-intersection-observer'

const HeroBanner = ({
	aboveTheFold,
	hasVideo,
	title,
	titleProps,
	subtitle,
	subtitleProps,
	description,
	descriptionProps,
	cta,
	ctaProps,
	additionalContent,
	backgroundComponent,
	url,
	onClick,
	...props
}) => {
	const fullUrl = url ?? (cta && cta?.length === 1 ? cta[0].url : undefined)
	const fullOnClick = onClick ?? (cta && cta?.length === 1 ? cta[0].onClick : undefined)

	const noFadeInCssClass = props.className.indexOf('no-fade-in') > -1

	const [containerRef, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	})

	const showIn = noFadeInCssClass || inView

	return (
		<Hero
			overlayComponent={
				<Container ref={containerRef}>
					{subtitle && (
						<Subtitle show={showIn} variant="subheadline1" tabIndex="0" {...subtitleProps}>
							{subtitle}
						</Subtitle>
					)}
					{title && (
						<Title show={showIn} variant="headlinebig" tabIndex="0" {...titleProps}>
							{title}
						</Title>
					)}
					{description && (
						<Description show={showIn} variant="subheadline1" tabIndex="0" {...descriptionProps}>
							{description}
						</Description>
					)}
					{cta && cta?.length > 0 && (
						<CtaContainer
							show={showIn}
							display="inline-flex"
							justifyContent={props.horizontalAlign}
							hasSiblings={!!description || !!title}
							{...ctaProps}
						>
							{cta.map(
								({ label, url, ...rest }, key) =>
									label && (
										<Grid item key={key}>
											<Button tabIndex="0" label={label} href={url} {...rest}>
												{label}
											</Button>
										</Grid>
									)
							)}
						</CtaContainer>
					)}
					{additionalContent}
				</Container>
			}
			backgroundComponent={
				<Link
					href={fullUrl}
					onClick={fullOnClick}
					sx={{
						cursor: fullUrl || fullOnClick ? 'pointer' : 'auto',
						height: aboveTheFold ? '100%' : undefined,
						display: hasVideo && aboveTheFold ? 'flex' : undefined,
					}}
					tabIndex="0"
				>
					{backgroundComponent}
				</Link>
			}
			{...props}
		/>
	)
}

HeroBanner.defaultProps = {
	...Hero.defaultProps,
}

HeroBanner.propTypes = {
	...Hero.propTypes,
	title: node,
	titleProps: object,
	subtitle: node,
	subtitleProps: object,
	description: node,
	descriptionProps: object,
	cta: arrayOf(shape({ ...Button.propTypes })),
	ctaProps: object,
	additionalContent: node,
}

export default HeroBanner
