/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const PlayIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M6.14 20.05c-.19 0-.37-.05-.54-.15-.35-.2-.56-.56-.56-.96V5.05c0-.4.21-.76.56-.96.35-.2.76-.19 1.11 0l11.72 6.95c.34.2.54.55.54.95s-.2.75-.54.95L6.71 19.89c-.17.1-.37.16-.56.16zm0-15.1s-.03 0-.05.01c-.03.02-.05.05-.05.09v13.89s.02.07.05.09c.03.02.07.02.1 0l11.72-6.95v-.17L6.19 4.97s-.03-.02-.05-.02z" />
	</svg>
))
PlayIconSvg.displayName = 'PlayIconSvg'

const PlayIcon = forwardRef((props, ref) => <Icon component={PlayIconSvg} ref={ref} {...props} />)
PlayIcon.displayName = 'PlayIcon'

PlayIcon.defaultProps = {
	...Icon.defaultProps,
}
PlayIcon.propTypes = {
	...Icon.propTypes,
}

export default PlayIcon
export { PlayIconSvg }
