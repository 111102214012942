import Typography from '../../atoms/typography'
import BHLink from '../../molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo', 'show'].includes(prop)
}

const fadeIn = (show) => `
	opacity: 0;
	transform: translateY(30px);
	transition: all .3s ease-in;
	${show && 'opacity: 1; transform: translateY(0px);'};
`

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	pointer-events: auto;
	padding: var(--size-40) var(--size-20);
	height: fit-content;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: var(--size-40);
	}
`

const Title = styled(Typography)`
	${({ theme }) => theme.typography.headlinebig};
	${({ show }) => fadeIn(show)};
`
const Subtitle = styled(Typography)`
	${({ show }) => fadeIn(show)};
	${({ theme }) => theme.typography.subheadline1};
	margin-bottom: var(--size-8);
`
const Description = styled('div')`
	${({ show }) => fadeIn(show)};
	${({ theme }) => theme.typography.headlineDescription};
	padding-top: var(--size-16);
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	${({ show }) => fadeIn(show)};
	flex-direction: unset;
	gap: var(--size-8);
	flex-wrap: wrap;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? 'var(--size-16)' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		column-gap: var(--size-16);
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton)``

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button }
