import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import genericFoursixtyConfigAggregator from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-foursixty/config-aggregator'
import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import BannerStickyShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-sticky/banner-sticky-shimmer'
import BannerEmotionalShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-emotional/banner-emotional-shimmer'
import BannerCategoryShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category/banner-category-shimmer'
import BannerCaptionShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-caption/banner-caption-shimmer'
import BannerTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text/banner-text-shimmer'
import BannerTextColumnShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text-column/banner-text-column-shimmer'
import BannerCoverShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-cover/banner-cover'
import GenericFoursixtyShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-foursixty/generic-foursixty-shimmer'
import tabsConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/tabs/config-aggregator'

const BannerMenu = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu'
		),
	{ loading: BannerMenuShimmer }
)

const BannerSticky = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-sticky/banner-sticky'
		),
	{ loading: BannerStickyShimmer }
)

const BannerEmotional = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-emotional/banner-emotional'
		),
	{ loading: BannerEmotionalShimmer }
)

const BannerCategory = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category/banner-category'
		),
	{ loading: BannerCategoryShimmer }
)

const GenericFoursixty = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-foursixty/generic-foursixty'
		),
	{ loading: GenericFoursixtyShimmer }
)

const BannerCaption = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-caption/banner-caption'
		),
	{ loading: BannerCaptionShimmer }
)

const BannerText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text/banner-text'
		),
	{ loading: BannerTextShimmer }
)

const BannerTextColumn = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text-column/banner-text-column'
		),
	{ loading: BannerTextColumnShimmer }
)

const TabStickyBar = dynamic(() =>
	import(
		'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/tab-sticky-bar/tab-sticky-bar'
	)
)

const BannerCover = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-cover/banner-cover'
		),
	{ loading: BannerCoverShimmer }
)

const BannerSimple = dynamic(() =>
	import('@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-simple')
)

const bannerMenuConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerMenu,
}

const bannerStickyConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerSticky,
}

const bannerEmotionalConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerEmotional,
}

const bannerCategoryConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerCategory,
}

const bannerCaptionConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerCaption,
}

const bannerTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerText,
}

const bannerTextColumnConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerTextColumn,
}

const bannerCoverConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerCover,
}
const bannerCaptionInsideConfig = {
	configAggregator: (node, props) => ({
		...bannerConfigAggregator(node, props),
		template: 'inside',
	}),
	component: BannerCaption,
}

const bannerStickyDesktopConfig = {
	configAggregator: (node, props) => ({
		...bannerConfigAggregator(node, props),
		template: 'sticky-desktop',
	}),
	component: BannerSticky,
}

const bannerBoutiqueConfig = {
	configAggregator: (node, props) => ({
		...bannerConfigAggregator(node, props),
		template: 'boutique',
	}),
	component: BannerSimple,
}

const genericFoursixtyConfig = {
	configAggregator: genericFoursixtyConfigAggregator,
	component: GenericFoursixty,
}

const stickyBarConfig = {
	configAggregator: tabsConfigAggregator,
	component: TabStickyBar,
}

const customContentTypes = {
	'banner-menu': bannerMenuConfig,
	'slide-menu': bannerMenuConfig,
	'banner-sticky': bannerStickyConfig,
	'slide-sticky': bannerStickyConfig,
	'banner-emotional': bannerEmotionalConfig,
	'slide-emotional': bannerEmotionalConfig,
	'generic-foursixty': genericFoursixtyConfig,
	'banner-category': bannerCategoryConfig,
	'banner-caption': bannerCaptionConfig,
	'slide-caption': bannerCaptionConfig,
	'banner-text': bannerTextConfig,
	'slide-text': bannerTextConfig,
	'banner-text-column': bannerTextColumnConfig,
	'slide-text-column': bannerTextColumnConfig,
	'tabs-sticky-bar': stickyBarConfig,
	'banner-cover': bannerCoverConfig,
	'slide-cover': bannerCoverConfig,
	'banner-caption-inside': bannerCaptionInsideConfig,
	'slide-caption-inside': bannerCaptionInsideConfig,
	'banner-sticky-desktop': bannerStickyDesktopConfig,
	'slide-sticky-desktop': bannerStickyDesktopConfig,
	'banner-boutique': bannerBoutiqueConfig,
	'slide-boutique': bannerBoutiqueConfig,
}

export default customContentTypes
